import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import styled from 'styled-components'

import {FinePrint, H2, H3, Heading, Paragraph,} from '../components/base/Typography'

import demoimg from '../media/rawpixel-236143-unsplash.jpg'
import bl1 from '../media/brooke-lark-96398-unsplash.jpg'
import bl2 from '../media/brooke-lark-158017-unsplash.jpg'
import bl4 from '../media/brooke-lark-250695-unsplash.jpg'
import jp from '../media/jennifer-pallian-306915-unsplash.jpg'
import breakfast from '../media/alexander-mils-365917-unsplash.jpg'
import ocr from '../media/ocr.png'
import phone3 from '../media/eaters-collective-129481-unsplash.jpg'
import rp from '../media/rachel-park-366508-unsplash.jpg'
import cook from '../media/cook.jpeg'
import ic2 from '../media/ice-cream-2.jpeg'
import ic3 from '../media/ice-cream-3.jpeg'
import ic4 from '../media/ice-cream-4.jpeg'
import ic5 from '../media/ice-cream-5.jpeg'
import ic6 from '../media/ice-cream-6.jpeg'
import ic7 from '../media/ice-cream-7.jpeg'
import ic8 from '../media/ice-cream-8.jpeg'
import ic9 from '../media/ice-cream-9.jpeg'
import ic10 from '../media/ice-cream-10.jpeg'

import ItemTag from '../components/item/ItemTag'

import Button from '../components/base/Button'
import Polaroid, {Photo} from '../components/base/Polaroid'

import MealCard from '../components/meal/MealCard'
import {Card} from '../components/base/Card'

import {Col, Container, Responsive, Row, Section,} from '../components/containers/Layout'
import {List, ListItem} from '../components/containers/List'

import {VISION_RESPONSE} from '../data/api-responses'

import Tag from '../components/Tag'
import Helmet from 'react-helmet'
import Contact from "../components/Contact";

const shadow = (v = 1) => ({
  boxShadow: `0 ${3 + v / 4}px ${6 + v}px rgba(0, 0, 0, ${0.1 * v})`,
})

const ImageTile = styled.img`
  object-fit: cover;
  border-radius: 12px;
  margin: 10px;

  width: 250px;
  height: 250px;

  @media (max-width: 800px) {
    width: 220px;
    height: 220px;
  }

  @media (max-width: 500px) {
    width: 200px;
    height: 200px;
  }
`

const Hero = () => (
  <Section
    style={{
      paddingBottom: 300,
      paddingTop: 100,
    }}>
    <Container>
      <Col
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}>
        {/*<img src={logo} height={120}/>*/}
        <Heading
          large
          align={'left'}
          style={{ marginTop: 20, marginBottom: 20 }}>
          Build Smarter <br /> Food Applications.
        </Heading>
        <Paragraph align={'left'} narrow>
          Use state of the art machine learning algorithms to recognize,
          understand and reason about food.
        </Paragraph>
        {/* <Link to="/request-demo/">
          <Button size={'xl'} style={{ margin: '20px 0' }}>
            Book A Demo
          </Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const ImageTiles = () => (
  <Section style={{ backgroundColor: 'white', flexWrap: 'inherit' }}>
    <Row id={'image-grid'} style={{ flex: 0, maxWidth: 1000 }}>
      <Col>
        <ImageTile src={bl1} style={{ ...shadow() }} />
        <ImageTile src={demoimg} style={{ ...shadow() }} />
      </Col>
      <Col>
        <ImageTile src={bl2} style={{ ...shadow() }} />
        <ImageTile src={rp} style={{ ...shadow() }} />
      </Col>
      <Col>
        <ImageTile src={phone3} style={{ ...shadow() }} />
        <ImageTile src={bl4} style={{ ...shadow() }} />
      </Col>
    </Row>
  </Section>
)

const FoodRecognition = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Row className={'sm-full'} wrap="no-wrap">
        <div>
          <Responsive>
            {size => (
              <Polaroid
                src={jp}
                size={size ? Math.max(220, Math.min(300, size.width / 3)) : 300}
              />
            )}
          </Responsive>
        </div>
        <div style={{ padding: 5 }}>
          {VISION_RESPONSE.items
            .slice(0, 6)
            .map(x => (
              <ItemTag
                key={x.item.id}
                item={x.item}
                inline
                style={{ opacity: x.score * 8 }}
              />
            ))}
        </div>
      </Row>
      <Col>
        <H2>Food Recognition</H2>
        <Paragraph narrow>
          Use our state of the art image recognition models to analyze what your
          users eat, determine what's trending and make your content easier to
          find.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Hierarchical Predictions</H3>
            <Paragraph narrow>
              Detect high level categories like beverage and soup as well as
              specific dishes and ingredients.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Categorization</H3>
            <Paragraph narrow>
              Predict item attributes like cuisine, preparation method and
              flavor.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Personalization</H3>
            <Paragraph narrow>
              Get unmatched recognition accuracy with models that use a user's
              eating habits to refine predictions and automatically learn to
              recognize past meals.
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/food-recognition'}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const FoodLogging = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container>
      <Col className={'sm-full'}>
        <MealCard
          style={shadow()}
          meal={{
            id: '8293911e-d0f5-41e3-a97b-e7084e60c431',
            title: 'Rhubarb Pie',
            images: [
              {
                id: '5b5f8288-e590-4375-944b-2372c78f3a61',
                url: bl1,
              },
            ],
            entries: [],
          }}
        />
        <MealCard
          style={shadow()}
          expanded
          meal={{
            id: '8293911e-d0f5-41e3-a97b-e7084e60c431',
            title: 'Pancakes with Cream and Strawberries',
            images: [
              {
                id: '5b5f8288-e590-4375-944b-2372c78f3a61',
                url: jp,
              },
            ],
            entries: [],
          }}
        />
        <MealCard
          style={shadow()}
          meal={{
            id: '8293911e-d0f5-41e3-a97b-e7084e60c431',
            title: 'Muesli with Greek Yogurt and Nectarines',
            images: [
              {
                id: '5b5f8288-e590-4375-944b-2372c78f3a61',
                url: breakfast,
              },
            ],
            entries: [],
          }}
        />
      </Col>
      <Col>
        <H2>Intelligent Food Logging SDK</H2>
        <Paragraph>
          Automate meal logging with a fully featured machine learning based
          food logging SDK.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Engaging Image Based Logging</H3>
            <Paragraph narrow>
              With food recognition logging a meal takes as little as 5 seconds.
              Our machine learning models learn to recognize past meals and
              improve with usage.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Comprehensive Nutrition Database</H3>
            <Paragraph narrow>
              Integrated with our Food Knowledge Graph that contains a large set
              of commonly eaten foods, with nutrition facts, and hierarchical
              structure.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Personalized Search and Recommendations</H3>
            <Paragraph narrow>
              Includes faceted instant search that's personalized to the user,
              so most likely foods can be found with a single key stroke.
            </Paragraph>
          </ListItem>
        </List>

        {/*<Link to={'/drafts/food-logging-sdk/'} state={{product: 'Food Logging SDK'}}>*/}
        {/* <Link to={'/request-demo/'} state={{product: 'Food Logging SDK'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const KnowledgeGraph = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container>
      <Col>
        <H2>Food Knowledge Graph</H2>
        <Paragraph narrow>
          Reason about food. Get access to our database of nutrition
          information, ingredients, categorizations, and relations.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Food Hierarchy</H3>
            <Paragraph narrow>
              Organized from high level concepts like beverage and sandwich down
              to specific branded products and variants.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Categorization</H3>
            <Paragraph narrow>
              Categorized by dietary trends, cuisine, preparation method and
              restrictions.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Nutrition Information</H3>
            <Paragraph narrow>
              Most items include calories, macro and micro nutrients as well as
              ingredients.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Trends</H3>
            <Paragraph narrow>
              Statistics on popularity, most likely consumption time and most
              likely items to be consumed with.
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Food Knowledge Graph'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
      <Col className={'sm-full'}>
        <Card lifted>
          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Name</FinePrint>
            <H3>Special K Cereal</H3>
          </div>

          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Brand</FinePrint>
            <H3>Kellogg's</H3>
          </div>

          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Parents</FinePrint>
            <Tag>Breakfast Cereal</Tag>
          </div>

          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Children</FinePrint>
            <Tag>Special K Red Berries</Tag>
            <Tag>Special K Vanilla Almond</Tag>
            <Tag>Special K Chocolatey Delight</Tag>
            <Tag>Special K Cinnamon Pecan</Tag>
          </div>

          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <div style={{ display: 'inline-block' }}>
              <FinePrint bold>Popularity</FinePrint>
              <H2>0.7</H2>
            </div>

            <div style={{ display: 'inline-block' }}>
              <FinePrint bold>Calories</FinePrint>
              <H2>120</H2>
            </div>
          </div>
        </Card>
      </Col>
    </Container>
  </Section>
)

const OCR = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col className={'sm-full'}>
        <img
          src={ocr}
          style={{ maxHeight: 600, maxWidth: '100%' }}
          alt="OCR a bag of chips"
        />
      </Col>
      <Col>
        <H2>Nutrition Fact OCR</H2>
        <Paragraph>
          Collect nutrition information from any package using tailored optical
          character recognition models.
        </Paragraph>

        <List>
          <ListItem>
            <H3>Unmatched Accuracy</H3>
            <Paragraph>
              Unlike a standard OCR solution, our models are optimized for
              nutrition labels, leading to much higher accuracy.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Structured Output</H3>
            <Paragraph>
              Returns structured nutrition fact responses that are compatible
              with out knowledge graph representation.
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Nutrition Fact OCR'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const NLP = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container>
      <Col>
        <H2>
          Natural Language Understanding
          <Tag>Coming Soon</Tag>
        </H2>
        <Paragraph narrow>
          Turn unstructured text into a semantic representation that's easier to
          index and search.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Parsing</H3>
            <Paragraph narrow>
              Parse food names and recipes, identifying items, preparation
              methods, brands and serving sizes.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Categorization</H3>
            <Paragraph narrow>
              Predict cuisine, dish types, high-level categories and ingredients
              from text.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Semantic Similarity</H3>
            <Paragraph narrow>
              Make sense of user generated text by find semantically similar
              items in your database.
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'NLP'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
      <Col className={'sm-full'}>
        <Card style={{ boxShadow: '1px 4px 20px -2px rgba(0,0,0,0.15)' }}>
          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Input</FinePrint>
            <H3>"Creamy Chicken and Wild Rice Soup"</H3>
          </div>

          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Parsed</FinePrint>
            <H3>
              <Tag type={'CONST'}>Creamy</Tag>
              <Tag type={'ING'}>Chicken</Tag> and{' '}
              <Tag type={'ING'}>Wild Rice</Tag>
              <Tag type={'DISH'}>Soup</Tag>
            </H3>
          </div>

          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Hierarchy</FinePrint>
            <Tag>Chicken</Tag>
            <FinePrint bold inline>
              {' '}
              is type of{' '}
            </FinePrint>
            <Tag>Meat</Tag>
            <br />
            <Tag>Wild Rice</Tag>
            <FinePrint bold inline>
              {' '}
              is type of{' '}
            </FinePrint>
            <Tag>Rice</Tag>
            <br />
            <Tag>Creamy Soup</Tag>
            <FinePrint bold inline>
              {' '}
              is type of{' '}
            </FinePrint>
            <Tag>Soup</Tag>
          </div>

          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Categorization</FinePrint>
            <div>
              <div style={{ minWidth: 50, display: 'inline-block' }}>
                <FinePrint inline>Cuisine:</FinePrint>
              </div>
              <Tag score={0.8}>American</Tag> <Tag score={0.6}>English</Tag>
            </div>
            <div>
              <div style={{ minWidth: 50, display: 'inline-block' }}>
                <FinePrint inline>Dietary:</FinePrint>
              </div>
              <Tag score={0.9}>Contains Meat</Tag>{' '}
              <Tag score={0.8}>Contains Dairy</Tag>
            </div>
            <div>
              <div style={{ minWidth: 50, display: 'inline-block' }}>
                <FinePrint inline>Meal:</FinePrint>
              </div>
              <Tag score={0.7}>Dinner</Tag> <Tag score={0.5}>Lunch</Tag>
            </div>
          </div>
        </Card>
      </Col>
    </Container>
  </Section>
)

const VisualSearch = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container>
      <Col className={'sm-full md-full'} wrap="no-wrap">
        <div>
          <Responsive>
            {({ width } = {}) => (
              <div>
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic10}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic2}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic3}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic7}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic5}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic6}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic4}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic8}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic9}
                  size={width < 500 ? 100 : 120}
                />
              </div>
            )}
          </Responsive>
        </div>
      </Col>
      <Col>
        <H2>Visual Search</H2>
        <Paragraph>
          Make your content easier to find by enabling users to search by image.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Improve Relevance</H3>
            <Paragraph narrow>
              Help users find exactly what they're looking for instead of hoping
              that they'll be able to put together a specific enough text based
              query.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Match More Content</H3>
            <Paragraph narrow>
              Surface more results by match similar items when exact results are
              not available.
            </Paragraph>
          </ListItem>
          {/*<ListItem>*/}
          {/*<H3>Increase Engagement</H3>*/}
          {/*<Paragraph narrow>Provide an engaging visual experience.</Paragraph>*/}
          {/*</ListItem>*/}
          {/*<ListItem>*/}
          {/*<H3>Show Similar Items</H3>*/}
          {/*<Paragraph narrow>*/}
          {/*Use visual similarity to suggest other items that the user might*/}
          {/*be interested in.*/}
          {/*</Paragraph>*/}
          {/*</ListItem>*/}
          <ListItem>
            <H3>Understand User Intent</H3>
            <Paragraph narrow>
              View what your users are actually looking for to get deeper
              insights on their intent.
            </Paragraph>
          </ListItem>
        </List>
        {/*<Link to={'/drafts/visual-search/'} state={{product: 'Visual Search'}}>*/}
        {/* <Link to={'/request-demo/'} state={{product: 'Food Logging SDK'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const Recommendations = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col className={'sm-full'}>
        <div>
          <Responsive>
            {({ width } = {}) => (
              <div>
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic10}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic2}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic3}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic7}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic5}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic6}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic4}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic8}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic9}
                  size={width < 500 ? 100 : 120}
                />
              </div>
            )}
          </Responsive>
        </div>
      </Col>
      <Col>
        <H2>Recommendations</H2>
        <Paragraph>
          Reason about food. Get access to our database of nutrition
          information, ingredients, categorizations, and relations.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Next Meal Suggestions</H3>
            <Paragraph narrow>
              With food recognition logging a meal takes as little as 5 seconds.
              Our machine learning models learn to recognize past meals and
              improve with usage
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Healthy Alternatives</H3>
            <Paragraph narrow>
              Let users log in a snap with past meal recognition. Models are
              trained to recognize previously eaten meals.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Basket Completion</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Recommendations'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const ProductDigitization = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col className={'sm-full'}>
        <div>
          <Responsive>
            {({ width } = {}) => (
              <div>
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic10}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic2}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic3}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic7}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic5}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic6}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic4}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic8}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic9}
                  size={width < 500 ? 100 : 120}
                />
              </div>
            )}
          </Responsive>
        </div>
      </Col>
      <Col>
        <H2>Product Digitization</H2>
        <Paragraph>
          Reason about food. Get access to our database of nutrition
          information, ingredients, categorizations, and relations.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Data Collection Apps</H3>
            <Paragraph narrow>
              With food recognition logging a meal takes as little as 5 seconds.
              Our machine learning models learn to recognize past meals and
              improve with usage
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Label and Brand Detection</H3>
            <Paragraph narrow>
              Let users log in a snap with past meal recognition. Models are
              trained to recognize previously eaten meals.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>OCR</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Categorization</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Inventory Management</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Automated Checkout</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Product Digitization'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const DietarySurveys = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col className={'sm-full'}>
        <div>
          <Responsive>
            {({ width } = {}) => (
              <div>
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic10}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic2}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic3}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic7}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic5}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic6}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic4}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic8}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic9}
                  size={width < 500 ? 100 : 120}
                />
              </div>
            )}
          </Responsive>
        </div>
      </Col>
      <Col>
        <H2>Dietary Surveys</H2>
        <Paragraph>
          Use Bitesnap to conduct nutrition research, market research, etc
        </Paragraph>
        <List>
          <ListItem>
            <H3>Data Collection Apps</H3>
            <Paragraph narrow>
              With food recognition logging a meal takes as little as 5 seconds.
              Our machine learning models learn to recognize past meals and
              improve with usage
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Label and Brand Detection</H3>
            <Paragraph narrow>
              Let users log in a snap with past meal recognition. Models are
              trained to recognize previously eaten meals.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>OCR</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Categorization</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Inventory Management</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Automated Checkout</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Dietary Surveys'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const NutritionInfo = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col className={'sm-full'}>
        <div>
          <Responsive>
            {({ width } = {}) => (
              <div>
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic10}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic2}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic3}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic7}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic5}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic6}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic4}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic8}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic9}
                  size={width < 500 ? 100 : 120}
                />
              </div>
            )}
          </Responsive>
        </div>
      </Col>
      <Col>
        <H2>Nutrition Information</H2>
        <Paragraph>
          Reason about food. Get access to our database of nutrition
          information, ingredients, categorizations, and relations.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Generate Nutrition Facts</H3>
            <Paragraph narrow>
              With food recognition logging a meal takes as little as 5 seconds.
              Our machine learning models learn to recognize past meals and
              improve with usage
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Categorize</H3>
            <Paragraph narrow>
              Let users log in a snap with past meal recognition. Models are
              trained to recognize previously eaten meals.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Dietary Restrictions</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Enter In Minutes</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Nutrition Info'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const RecipeSearch = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col className={'sm-full'}>
        <div>
          <Responsive>
            {({ width } = {}) => (
              <div>
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic10}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic2}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic3}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic7}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic5}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic6}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic4}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic8}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic9}
                  size={width < 500 ? 100 : 120}
                />
              </div>
            )}
          </Responsive>
        </div>
      </Col>
      <Col>
        <H2>Intelligent Recipe Search</H2>
        <Paragraph>
          Reason about food. Get access to our database of nutrition
          information, ingredients, categorizations, and relations.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Grocery Recommendations</H3>
            <Paragraph narrow>
              With food recognition logging a meal takes as little as 5 seconds.
              Our machine learning models learn to recognize past meals and
              improve with usage
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Label and Brand Detection</H3>
            <Paragraph narrow>
              Let users log in a snap with past meal recognition. Models are
              trained to recognize previously eaten meals.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>OCR</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Categorization</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Inventory Management</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Automated Checkout</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Recipe Search'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const MealPlanning = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col className={'sm-full'}>
        <div>
          <Responsive>
            {({ width } = {}) => (
              <div>
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic10}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic2}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic3}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic7}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic5}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic6}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic4}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic8}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic9}
                  size={width < 500 ? 100 : 120}
                />
              </div>
            )}
          </Responsive>
        </div>
      </Col>
      <Col>
        <H2>Meal Planning</H2>
        <Paragraph>
          Reason about food. Get access to our database of nutrition
          information, ingredients, categorizations, and relations.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Data Collection Apps</H3>
            <Paragraph narrow>
              With food recognition logging a meal takes as little as 5 seconds.
              Our machine learning models learn to recognize past meals and
              improve with usage
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Label and Brand Detection</H3>
            <Paragraph narrow>
              Let users log in a snap with past meal recognition. Models are
              trained to recognize previously eaten meals.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>OCR</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Categorization</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Inventory Management</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Automated Checkout</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Meal Planning'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const Internationalization = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col className={'sm-full'}>
        <div>
          <Responsive>
            {({ width } = {}) => (
              <div>
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic10}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic2}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic3}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic7}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic5}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic6}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic4}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic8}
                  size={width < 500 ? 100 : 120}
                />
                <Polaroid
                  style={{ margin: width < 500 ? 4 : 6 }}
                  src={ic9}
                  size={width < 500 ? 100 : 120}
                />
              </div>
            )}
          </Responsive>
        </div>
      </Col>
      <Col>
        <H2>Internationalization</H2>
        <Paragraph>
          Reason about food. Get access to our database of nutrition
          information, ingredients, categorizations, and relations.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Data Collection Apps</H3>
            <Paragraph narrow>
              With food recognition logging a meal takes as little as 5 seconds.
              Our machine learning models learn to recognize past meals and
              improve with usage
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Label and Brand Detection</H3>
            <Paragraph narrow>
              Let users log in a snap with past meal recognition. Models are
              trained to recognize previously eaten meals.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>OCR</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Categorization</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Inventory Management</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Automated Checkout</H3>
            <Paragraph narrow>
              Log foods using images, text search or by scanning a barcode. Our
              nutrition database includes full nutrition information,
              ingredients and hierarchical categories
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Internationalization'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

export const CustomSolutions = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container>
      <Col style={{ justifyContent: 'center', alignItems: 'center' }}>
        <H2>Custom Solutions</H2>
        <Paragraph narrow>
          We're committed to your success and will work with you to provide an
          optimal solution for your problem.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Custom Models</H3>
            <Paragraph narrow>
              If you need to detect or parse specific concepts we can help you
              tune our models for your use case.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Specialized Deployments</H3>
            <Paragraph narrow>
              We can help you deploy your models for bulk, offline or low
              latency inference, including on premise and on mobile devices.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Integration Assistance</H3>
            <Paragraph narrow>
              We can help you get from 0 to 1 and make the most of our
              technology with hands on integration support.
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo/'} state={{product: 'Custom Solution'}}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
      <Col className={'sm-full'} align={'center'} justfiy={'center'}>
        {/*<Photo src={manCafe} style={{ maxWidth: 400 }} />*/}
        <Photo src={cook} style={{ width: 400, minWidth: 300 }} />
      </Col>
    </Container>
  </Section>
)

const LandingPage = () => (
  <Layout>
    <Helmet
      title={'Bite AI - Food Intelligence Platform'}
      meta={[
        {
          name: 'description',
          content: 'Bite AI - Food Intelligence Platform',
        },
        {
          property: 'og:description',
          content: 'Bite AI - Food Intelligence Platform',
        },
        {
          name: 'keywords',
          content:
            'food recognition, api, deep learning, image recognition,' +
            'food recognition api, food search, food image recognition,' +
            ' food knowledge graph',
        },
      ]}
    />
    <Hero />
    <ImageTiles />
    <FoodRecognition reverse />
    <FoodLogging />
    <KnowledgeGraph />
    <OCR />
    <NLP />
    <VisualSearch />
    {/*<Recommendations reverse/>*/}
    {/*<ProductDigitization/>*/}
    <CustomSolutions />
    <Contact />
  </Layout>
)

export default LandingPage
