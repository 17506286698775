import React from 'react'

import styled from 'styled-components'

import Image from './Image'

const PolaroidCard = styled.div`
  display: inline-block;
  background-color: white;
  padding: ${({ size = 300} = {}) => size * 0.05}px;
  padding-bottom: ${({ size = 300} = {}) => size * 0.15}px;
  border-radius: 5px;
  box-shadow: ${({ lift = 1} = {}) => `0 ${3 + lift / 4}px ${6 + lift}px rgba(0, 0, 0, ${0.1 * lift})`};
  
  ${({onClick}) => onClick && `cursor: pointer;`}
  
  transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out;
  
  &:hover {
    transform: scale(1.01) rotate(0.5deg);
    box-shadow: 0 ${`${3 + 10 / 4}px ${6 + 10}px rgba(0, 0, 0, ${0.1})`};
  }
`


const Polaroid = ({ src, path, children, size = 300, style, lift=1, alt=undefined, ...rest } = {}) =>
  <PolaroidCard style={style} size={size} lift={lift} {...rest}>
    {src &&
      <img
        src={src}
        width={size}
        height={size}
        style={{ objectFit: 'cover', borderRadius: 4 }}
        alt={alt}
        {...rest}
      />}

      {path &&
      <Image
        path={path}
        style={{
          width: size,
          height: size,
          objectFit: 'cover',
          borderRadius: 4
        }}
        alt={alt}
        fadeIn={false}
        critical
        backgroundColor={'#333'}
        {...rest}
      />}
    {children}
  </PolaroidCard>

export default Polaroid




export const Photo = styled.img`
  transform: rotate(1deg);
  border-radius: 3px;
  // box-shadow: ${({ lift = 1} = {}) => `0 ${3 + lift / 4}px ${6 + lift}px rgba(0, 0, 0, ${0.1 * lift})`};
  box-shadow: 0 5px 35px -3px rgba(0, 0, 0, .4); 
  max-width: 100%;
  
  transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out;
  
  ${({onClick}) => onClick && `cursor: pointer;`}
  
  //&:hover {
  //  transform: scale(1.01) rotate(1.2deg);
  //  box-shadow: 0 5px 35px -6px rgba(0, 0, 0, .3);
  //}
`