import React from 'react'

import { Card } from '../base/Card'
import { H3 } from '../base/Typography'

export default class MealCard extends React.Component {
  static defaultProps = {
    meal: {
      id: '8293911e-d0f5-41e3-a97b-e7084e60c431',
      title: 'Chocolate Chip Cookies with Milk',
      description: 'wat',
      images: [
        {
          id: '5b5f8288-e590-4375-944b-2372c78f3a61',
          url:
            'https://biteai-api-media.s3.amazonaws.com/staging/5b/5f/8288-e590-4375-944b-2372c78f3a61.jpeg?AWSAccessKeyId=ASIAIJMA476U4FSBIADQ&Signature=93%2BuJLQ7eoEHEPlVySfm2RTXVAQ%3D&x-amz-security-token=FQoDYXdzEH0aDCcSaLdbLiHZcumB%2BSK3A%2FwoqhlITUaa69wji9KGtM1vwMUnC6%2FfkoyEbvA1dfhFIif3zS7IySepHgwHtCjTrCeNvjCF89Fk6Q7Hz6O%2FabjgsNW0pJRr4d1nwxSVfuZiMxWP7Caqi22MRMEIa%2FL2RqsypVOm5f9ImIvB%2BIsNF2FEEU8Bp3Gh0kALzry82edChelqQMlKAV6L2W3mG2k4jZDbOYlo0yXA%2Bi3LEvTM1%2F209%2Fno9of3mbuAXVs65l8xM4ErBE%2F1E76S81kHptul2JcRWy7TEwfD%2BIAMLzx%2FYa%2Ft8kUge9C%2FbI%2BZxa3syzVemBrWwSfRHvIyMFOSSWTokZgBq3F5UcsjpXILP4UWJV11voHYpgrOrd4RxJ4rDtorm7JHWOZpwlMrzIjmrctOc7byQ5g2H48gwwXgNVRRElnKZZyyibvdaY%2BTEqd8AI1rhzKt7AhzwpyXIB3SHIfCz37o8cmseAw3ot6qt%2BK5oZ7y7DykLhPSOIoWS8qTuPvgbSdYaNkvvoxnUDuz%2F7CO0CRCqvLO%2BZBxskQDpVVx4eueAM2osZrgTmOdVsXprv0ylRYIdPCTKCakQsQcKYJn6DOIIdHRa2YosLaD1wU%3D&Expires=1524693296',
          type: '',
          source_url: 'https://i.redd.it/exkuwlwgp9s01.jpg'
        }
      ],
      entries: [],
      local_eaten_at_time: null,
      utc_eaten_at_time: null,
      time_created: '2018-04-23T21:03:54.533476Z',
      time_modified: '2018-04-23T21:03:54.533514Z'
    },
    height: 120
  }
  render() {
    return (
      <Card
        gradient
        url={this.props.meal.images[0].url}
        style={{
          // height: this.props.height,
          width: '96%',
          maxWidth: 500,
          margin: 10,
          flex: 1,
          paddingTop: this.props.expanded ? '60%' : '30%',
          // display: 'flex',
          // flexDirection: 'column',
          // justifyContent: 'flex-end',
          ...this.props.style
        }}
        className={"meal-card"}
      >

        <div style={{padding: 10, zIndex: 2, position: 'absolute', bottom: 0, left: 0, right: 0}}>
          <H3 style={{color: 'white', padding: 0, margin: 0}}>{this.props.meal.title}</H3>
          {this.props.children}
        </div>
      </Card>
    )
  }
}
