import React from 'react'
import styled from 'styled-components'

const TagContainer = styled.div`
  display: inline-block;
  box-sizing: border-box;
  padding: 3px 6px;
  margin: 0.1rem 0.1rem;
  border: ${props =>
    props.selected ? '2px solid #DDD' : '1px solid #E6E6E6'};
  border-radius: 8px;
  font-size: 0.8rem;
  font-family: 'Poppins', 'Montserrat', 'Helvetica Neue', 'Helvetica', Arial,
    sans-serif;
  font-weight: bold;
  color: #343434;
  background-color: ${props =>
    props.selected ? '#DADADA' : '#eee'};
`

const Type = styled.span`
  font-weight: bold;
  font-size: 0.7rem;
  opacity: 80%;
`

const Tag = ({
  children,
  type = undefined,
  color,
  score,
  opacity,
  style,
  selected
} = {}) => (
  <TagContainer selected={selected} style={{ backgroundColor: color, opacity, ...style }}>
    {type && <Type>{type} </Type>}
    {children}
    {score && <Type> {score}</Type>}
  </TagContainer>
)

export default Tag
