import React, { PureComponent, Component } from 'react'

import Anime from '../../base/Anime'


export class ItemContainer extends PureComponent {
  reverse = () => this.a.reverse()
  render() {
    return (
      <div
        style={{
          // backgroundColor: '#EEE',
          padding: 10,
          ...this.props.style
        }}>
        <Anime
          ref={r => this.a = r}
          config={{
            easing: 'easeOutExpo',
            duration: 1000,
            translateY: [30, 0],
            opacity: [0.6, 1],
            scale: [0.97, 1]
          }}>
          {this.props.items.map(item =>
            <div id={item.id} key={item.id} style={{ display: 'inline-block' }}>
              <ItemTag key={item.id} item={item} inline />
            </div>
          )}
        </Anime>
      </div>
    )
  }
}

const Text = ({ size, weight, children }) =>
  <p
    style={{
      padding: 1,
      margin: 0,
      fontSize: size,
      fontWeight: weight,
      fontFamily: 'sans-serif'
    }}>
    {children}
  </p>

export default class ItemTag extends Component {
  static defaultProps = {
    size: 15
  }
  state = {
    selected: false
  }

  toggleSelected = () => this.setState({ selected: this.state.selected })

  render() {
    const { name, children = [] } = this.props.item
    // details, brand
    return (
      <div
        className="item-preview"
        style={{
          padding: 8,
          margin: 8,
          borderRadius: 12,
          backgroundColor: '#EEE',
          // border: `2px solid ${this.state.selected ? '#77FF77' : '#AAA'}`,
          ...(this.props.inline ? { display: 'inline-block' } : {}),
          ...this.props.style
        }}>
        <Text size={this.props.size} weight={600}>{name}</Text>
        {/*<Text size={this.props.size - 3} weight={400}>{details}</Text>*/}
        {/*<p>{brand && brand.name}</p>*/}

        {!this.props.inline &&
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              whiteSpace: 'nowrap',
              'flexWrap': 'wrap',
              width: '100%'
            }}>
            {children.map(c =>
              <ItemTag
                inline
                size={10}
                item={c}
                style={{ backgroundColor: 'white' }}
              />
            )}
          </div>}

      </div>
    )
  }
}

export class ItemTagLoader extends Component {
  static defaultProps = {
    width: 30,
    row: 0,
    x: 0
  }

  render() {
    return (
      <rect
        rx="5"
        ry="5"
        height="15"
        width={this.props.width.toString()}
        x={this.props.x.toString()}
        y={(this.props.row * 21).toString()}
      />
    )
  }
}
