import React, { Component } from 'react'
import { isEqual, omit, functions } from 'lodash'

import anime from 'animejs'

/**
 * wrapper for anime.js
 * all children must have id set to work
 */
export default class Anime extends Component {
  static defaultProps = {
    children: [],
    config: {}
  }
  targets = {}

  play = () => this.anime.play()
  pause = () => this.anime.pause()
  restart = () => this.anime.restart()
  reverse = () => this.anime.reverse()

  componentDidMount() {
    this.configure(this.props.config)
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(
        omit(this.props, functions(this.props), 'children'),
        omit(nextProps, functions(nextProps), 'children')
      )
    ) {
      this.configure(nextProps)
    }
  }

  configure = config => {
    const targets = Object.values(this.targets)
    anime.remove(targets)
    this.anime = anime({ ...config, targets })
  }

  addTarget = (node, element) => {
    if (node !== null) {
      this.targets[element.props.id] = node
    } else {
      delete this.targets[element.props.id]
    }
  }

  render() {
    let { children } = this.props
    if (!Array.isArray(children)) children = [children]
    return children.map(child => {
      return React.cloneElement(child, {
        ref: ref => this.addTarget(ref, child),
        key: child.props.id
      })
    })
  }
}

export class AnimatedText extends Component {
  static defaultProps = {
    text: 'loading...',
    config: {
      loop: true,
      direction: 'alternate',
      easing: 'easeOutExpo',
      duration: 1000,
      translateX: [50, 0],
      delay: (e, idx) => idx * 100,
      opacity: [0.5, 1],
      scale: [0.7, 1]
    }
  }
  render() {
    const { text, style } = this.props

    return (
      <Anime config={this.props.config}>
        {text.split('').map((c, idx) => <span style={{display: 'inline-block', ...style}} id={idx} key={idx}>{c}</span>)}
      </Anime>
    )
  }
}
