import styled from 'styled-components'

export const Card = styled.div`
  position: relative;
  display: inline-block;
  background-color: ${({ color }) => color || '#fff'};
  border-radius: 5px;
  
  box-shadow: ${
    props => props.lifted 
      ? '1px 4px 20px -2px rgba(0,0,0,0.15)' 
      : '0 1px 2px rgba(0, 0, 0, 0.1)'};
  ${props => (props.animated ? animateShadow : '')}
  
  ${({ url }) =>
    url
      ? `
  background-image: url(${url});
  background-size: cover;
  overflow: hidden;
  background-position: center center;
  `
      : ''}
  
  ${({ gradient }) =>
    gradient
      ? `

    &::after {
      content  : "";
      position : absolute;
      z-index  : 1;
      bottom   : 0;
      left     : 0;
      pointer-events   : none;
      background-image : linear-gradient(to bottom, 
                        rgba(30,30,30, 0), 
                        rgba(30,30,30, .2) 90%);
      width    : 100%;
      height   : 100%;
    }
  `
      : ''}
`

const animateShadow = `
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  
  &::after {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    transform: scale(1.02, 1.02);
  }
  
  &:hover::after {
    opacity: 1;
  }
`
