import {Container, Section} from "./containers/Layout";
import {RequestDemoForm} from "./RequestDemoForm";
import React from "react";

const Contact = (props) => (
    <Section
        style={{
          backgroundColor: 'white',
        }}>
      <Container style={{justifyContent: 'center'}}>
        <RequestDemoForm {...props}/>
      </Container>
    </Section>
)

export default  Contact